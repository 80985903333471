import React from "react";

const Resend = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="16"
      height="16"
    >
      <path d="m24,12c0,6.617-5.383,12-12,12-4.055,0-7.795-2.059-9.996-5.37l-.004,1.37c0,.553-.448,1-1,1s-1-.447-1-1v-2.991c0-1.107.901-2.009,2.008-2.009h2.992c.552,0,1,.447,1,1s-.448,1-1,1h-1.637c1.776,3.06,5.052,5,8.637,5,5.514,0,10-4.486,10-10,0-.553.447-1,1-1s1,.447,1,1Zm-1-9c-.553,0-1,.447-1,1l-.004,1.355C19.807,2.04,16.107,0,12,0,5.383,0,0,5.383,0,12c0,.553.448,1,1,1s1-.447,1-1C2,6.486,6.486,2,12,2c3.64,0,6.9,1.921,8.666,5h-1.666c-.553,0-1,.447-1,1s.447,1,1,1h3c1.105,0,2-.895,2-2v-3c0-.553-.447-1-1-1Zm-13,14c-1.654,0-3-1.346-3-3v-4c0-1.654,1.346-3,3-3h4c1.654,0,3,1.346,3,3v4c0,1.654-1.346,3-3,3h-4Zm0-2h4c.552,0,1-.448,1-1v-4c0-.552-.448-1-1-1h-4c-.551,0-1,.448-1,1v4c0,.552.449,1,1,1Zm1.5-3h1c.553,0,1-.447,1-1s-.447-1-1-1h-1c-.552,0-1,.447-1,1s.448,1,1,1Z" />
    </svg>
  );
};

export default Resend;

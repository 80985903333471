import { Field, Formik } from "formik";
import { useLocation } from "react-router-dom";
import PageHeader from "../../../../../atoms/PageHeader";
import BackBtn from "../../../../../atoms/BackBtn";
import PageCard from "../../../../../atoms/PageCard";
import InputLayout from "../../../../../atoms/InputLayout";
import Error from "../../../../../atoms/Error";
import ImageSelector from "../../../../../atoms/ImageSelector/ImageSelector";
import { replaceSpacesInImageUrl } from "../../../../../../helpers/replaceSpaceInImageUrl";
import style from "./style.module.scss";
import CheckboxWrapper from "../../../../../atoms/CheckBoxWrapper/Checkbox";
import SelectDropdown from "../../../../../molecules/Dropdown";
import * as yup from "yup";
import CustomButton from "../../../../../atoms/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { forceTerminl } from "../../../../../../helpers/staticData";
import ColorPicker from "../../../../../atoms/ColorPicker";
import {
  getData,
  patchData,
  postData,
  patchFormData,
  postFormData,
} from "../../../../../../services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/toast";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import VehicleSelection from "./VehicleSelection";
const ImageSelectorStyle = {
  borderRadius: "10px",
  border: "1px solid #EFEFEF",
  padding: "10px",
  width: "200px",
  objectFit: "contain",
};
const AddTerminal = ({ type = "add" }) => {
  const { venue_id, id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [authorizationError, setAuthorizationError] = useState(false);
  const [cookies] = useCookies(["b_t", "bid"]);
  const location = useLocation();
  const venue_name = location?.state?.name;

  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "VenueTerminal/getVenueTerminalById",
        token: cookies.b_t,
        params: { terminal_id: id },
      });
      setInitialValues(res);
      // console.log('res',res)
    };

    if (type === "edit") {
      fetchData();
    }
  }, []);

  const initialData = {
    venue_id: venue_id,
    terminal_name: initialValues.terminal_name || null,
    description: initialValues.description || null,
    welcome_message: initialValues.welcome_message || null,
    exit_message: initialValues.exit_message || null,
    primary_theme_color: initialValues.primary_theme_color || "#6B66DA",
    secondary_theme_color: initialValues.secondary_theme_color || "#6B66DA",
    logo: initialValues.logo || null,
    remove_logo: initialValues.remove_logo || null,
    badge_logo: initialValues.badge_logo || null,
    remove_bage_logo: initialValues.remove_bage_logo || null,
    enable_printing_badge: initialValues.enable_printing_badge || null,
    is_vehicle_check: initialValues.is_vehicle_check || null,
    vehicles_allowed: initialValues.vehicles_allowed || null,
    authorization_id: initialValues.authorization_id || null,
    authorization_code: initialValues.authorization_code || null,
    pin: initialValues.pin || null,
  };
  const validationSchema = yup.object().shape({
    terminal_name: yup.string().required("Terminl Name is required"),
    description: yup.mixed().required("Terminal Description is required"),
    welcome_message: yup.string().required("Welcome Message is required"),
    exit_message: yup.string().required("Exit Meassge is required"),
    primary_theme_color: yup
      .string()
      .required("Primary Theme Color is required"),
    authorization_id: yup
      .string()
      .required("Terminal ID is required")
      .min(4, "Terminal ID must be at least 4 characters"),
    authorization_code: yup
      .string()
      .required("Terminal Password is required")
      .min(4, "Terminal Password must be at least 4 characters"),
    pin: yup
      .string()
      .required("PIN is required")
      .min(6, "PIN must be of 6 characters"),
    vehicles_allowed: yup.mixed().when("is_vehicle_check", {
      is: true,
      then: () =>
        yup
          .array()
          .of(yup.string())
          .min(1, "At least one vehicle must be allowed")
          .required("Vehicles are required when vehicle check is true"),
      otherwise: () => yup.mixed().nullable().notRequired(),
    }),
  });

  const checkAuthorzationId = async (id) => {
    try {
      const res = await getData({
        endpoint: "VenueTerminal/checkAuthorizationId",
        params: {
          business_id: cookies.bid,
          authorization_id: id,
        },
      });

      if (!res?.status) {
        setAuthorizationError(res?.message);
      } else {
        setAuthorizationError(false);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleSubmit = async (values) => {
    // console.log("values :", values);
    setIsSaving(true);

    try {
      const res = await postFormData({
        endpoint: "VenueTerminal/addVenueTerminal",
        data: { ...values, business_id: cookies.bid },
        token: cookies.b_t,
      });
      if (res) {
        toast.success("Terminal Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    setIsSaving(true);

    try {
      const res = await patchFormData({
        endpoint: "VenueTerminal/editVenueTerminal",
        token: cookies.b_t,
        data: { ...values, venue_id: venue_id },
        params: { terminal_id: id },
      });
      if (res) {
        toast.success("Terminal Details Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
      validateOnMount
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => (
        <>
          <PageHeader
            title={
              type === "add"
                ? `Add Terminal To ${venue_name}`
                : `Edit Terminal To ${venue_name}`
            }
          >
            <BackBtn />
          </PageHeader>
          <PageCard>
            <div className="row g-4 mb-1">
              <InputLayout>
                <label htmlFor="terminal_name">Terminal Name</label>
                <Field
                  type="text"
                  id=" terminal_name"
                  name="terminal_name"
                  placeholder="Terminal Name"
                  className={`${
                    touched.terminal_name && errors.terminal_name
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.terminal_name && errors.terminal_name && (
                  <Error error={errors.terminal_name} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="description">Description</label>
                <Field
                  type="text"
                  as="textarea"
                  id="description"
                  name="description"
                  placeholder="Description"
                  className={`${
                    touched.description && errors.description
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.description && errors.description && (
                  <Error error={errors.description} />
                )}
              </InputLayout>
            </div>

            <h4 className="fw-bolder mt-3">Visual Settings</h4>
            <hr></hr>
            <div className=" row g-4 mb-3 mt-0">
              <div className="col-md mt-0">
                <label htmlFor="">Terminal Logo</label>
                <ImageSelector
                  style={ImageSelectorStyle}
                  type="Company"
                  handleFile={(file) => {
                    setFieldValue("logo", file);
                    setFieldValue("remove_logo", false);
                  }}
                  aspect={2}
                  widthLimit={400}
                  heightLimit={400}
                  selectedImage={replaceSpacesInImageUrl(initialData?.logo)}
                  description="Logo Image Should be 400X400px and Size is 1MB"
                  onRemove={() => {
                    setFieldValue("remove_logo", true);
                  }}
                />
              </div>
              <div className="col-md mt-0">
                <label htmlFor="">Logo to be used on Badge Print out </label>
                <ImageSelector
                  style={ImageSelectorStyle}
                  type="Company"
                  handleFile={(file) => {
                    setFieldValue("badge_logo", file);
                    setFieldValue("remove_badge_logo", false);
                  }}
                  aspect={1}
                  widthLimit={400}
                  heightLimit={400}
                  selectedImage={replaceSpacesInImageUrl(
                    initialData?.badge_logo
                  )}
                  description="Logo Image Should be 400X400px and Size is 1MB"
                  onRemove={() => {
                    setFieldValue("remove_badge_logo", true);
                  }}
                />
              </div>
            </div>

            {console.log("er", errors)}
            <div className=" row g-4 mb-3">
              <InputLayout>
                <label htmlFor="welcome_message">Welcome Message</label>
                <Field
                  type="text"
                  id="welcome_message"
                  name="welcome_message"
                  placeholder="Welcome Message"
                  className={`${
                    touched.welcome_message && errors.welcome_message
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.welcome_message && errors.welcome_message && (
                  <Error error={errors.welcome_message} />
                )}
              </InputLayout>
              <InputLayout>
                <label htmlFor="exit_message">Exit Message</label>
                <Field
                  type="text"
                  id="exit_message"
                  name="exit_message"
                  placeholder="Exit Message"
                  className={`${
                    touched.exit_message && errors.exit_message
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.exit_message && errors.exit_message && (
                  <Error error={errors.exit_message} />
                )}
              </InputLayout>
            </div>
            <div className=" row g-4 mb-3">
              <InputLayout>
                <label htmlFor="primary_theme_color">
                  {" "}
                  Terminal Background Color
                </label>
                {/* <Field
                  type="text"
                  id="primary_theme_color"
                  name="primary_theme_color"
                  placeholder="Primary Theme Color"
                  className={`${
                    touched.primary_theme_color && errors.primary_theme_color
                      ? "error-input"
                      : ""
                  }`}
                /> */}
                <ColorPicker
                  onChange={(color) =>
                    setFieldValue("primary_theme_color", color)
                  }
                  selected={values.primary_theme_color}
                />
                {touched.primary_theme_color && errors.primary_theme_color && (
                  <Error error={errors.primary_theme_color} />
                )}
              </InputLayout>
              <InputLayout>
                <label htmlFor="primary_theme_color">
                  Component Theme Color
                </label>
                <ColorPicker
                  onChange={(color) =>
                    setFieldValue("secondary_theme_color", color)
                  }
                  selected={values.secondary_theme_color}
                />
                {touched.secondary_theme_color &&
                  errors.secondary_theme_color && (
                    <Error error={errors.secondary_theme_color} />
                  )}
              </InputLayout>
            </div>
            <div className="d-flex my-4 flex-column justify-content-between flex-wrap gap-3">
              <div className={`${style.checkbox_container}`}>
                <label htmlFor={`enable_printing_badge`}>
                  Enable badge printing from this terminal
                </label>
                <CheckboxWrapper
                  type="checkbox"
                  id="enable_printing_badge"
                  name="enable_printing_badge"
                  value={values.enable_printing_badge}
                  checked={values.enable_printing_badge}
                  onChange={() => {
                    setFieldValue(
                      "enable_printing_badge",
                      !values.enable_printing_badge
                    );
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </div>
              <div className={`${style.checkbox_container}`}>
                <label htmlFor={`is_vehicle_check`}>Vehicle Check</label>
                <CheckboxWrapper
                  type="checkbox"
                  id="is_vehicle_check"
                  name="is_vehicle_check"
                  value={values.is_vehicle_check}
                  checked={values.is_vehicle_check}
                  onChange={() => {
                    setFieldValue("is_vehicle_check", !values.is_vehicle_check);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </div>
            </div>

            {/* {values.is_vehicle_check && ( */}
            <div className="mt-2">
              <label>
                Select allowed vehicles type (when vehicle check is on)
              </label>
              <VehicleSelection
                selectedVehicles={initialData?.vehicles_allowed}
                handleAddVehicles={(vehicles) =>
                  setFieldValue("vehicles_allowed", vehicles)
                }
                isDisabled={!values.is_vehicle_check}
              />
            </div>
            {/* )} */}

            <h4 className="fw-bolder mt-5">Authorization Credentials</h4>
            <hr></hr>
            <div className="row g-4 mb-3">
              <InputLayout>
                <label htmlFor="authorization_id">Terminal ID</label>
                <Field
                  type="text"
                  id="authorization_id"
                  name="authorization_id"
                  placeholder="Terminal ID"
                  className={`${
                    (touched.authorization_id && errors.authorization_id) ||
                    authorizationError
                      ? "error-input"
                      : ""
                  }`}
                  onChange={(e) => {
                    handleChange(e);
                    if (
                      e.target.value !== initialData?.authorization_id &&
                      !errors.authorization_id
                    ) {
                      checkAuthorzationId(e.target.value);
                    } else {
                      setAuthorizationError(false);
                    }
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    if (
                      values.authorization_id !== initialData?.authorization_id
                    ) {
                      checkAuthorzationId(values.authorization_id);
                    } else {
                      setAuthorizationError(false);
                    }
                  }}
                />

                {((touched.authorization_id && errors.authorization_id) ||
                  authorizationError) && (
                  <Error
                    error={errors.authorization_id || authorizationError}
                  />
                )}
              </InputLayout>
              <InputLayout>
                <label htmlFor="authorization_code">Terminal Password</label>
                <Field
                  type="text"
                  id="authorization_code"
                  name="authorization_code"
                  placeholder="Terminal Password"
                  className={`${
                    touched.authorization_code && errors.authorization_code
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.authorization_code && errors.authorization_code && (
                  <Error error={errors.authorization_code} />
                )}
              </InputLayout>
              <InputLayout>
                <label htmlFor="pin">PIN</label>
                <Field
                  type="text"
                  id="pin"
                  name="pin"
                  placeholder="6 Digit PIN"
                  className={`${
                    touched.pin && errors.pin ? "error-input" : ""
                  }`}
                  onInput={(e) => {
                    e.preventDefault();
                    e.target.value = parseInt(e.target.value) || "";
                    e.target.value = e.target.value.toString().slice(0, 6);
                  }}
                />
                {touched.pin && errors.pin && <Error error={errors.pin} />}
              </InputLayout>
            </div>
            <div className="d-flex gap-3 mt-5">
              {type === "add" ? (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleSubmit(values)}
                  disabled={
                    !isValid || !dirty || isSaving || authorizationError
                  }
                >
                  Create Terminal
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              ) : (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleEdit(values)}
                  disabled={!isValid || isSaving || authorizationError}
                >
                  Update
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              )}

              {/* {console.log('avues', values)} */}

              <CustomButton
                text="Cancel"
                iconRequired={false}
                handleClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </PageCard>
        </>
      )}
    </Formik>
  );
};
export default AddTerminal;

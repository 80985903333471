// import React,{useState} from "react";
// import { Formik, Form, Field } from "formik";
// import * as yup from "yup";
// import { useCookies } from "react-cookie";
// import InputLayout from "../../../atoms/InputLayout";
// import Error from "../../../atoms/Error";
// import { postData } from "../../../../services";
// import toast from "react-hot-toast";
// import { confimationStyles, errorStyles} from "../../../../assets/styles/toast";

// const SupportRequestForm = ({onClose}) => {
//   const [cookies] = useCookies(["bid","b_t"]);
//   const [isSaving, setIsSaving] = useState(false);



//   const initialData = {
//     user_name: null,
//     business_email: null,
//     title: null,
//     description: null,
//   };

//   const validationSchema = yup.object().shape({
//     user_name: yup.string().required("User Name is required"),
//     business_email: yup.string().required("Email is required").email("Invalid email"),
//     title: yup.string().required("Title is required"),
//     description: yup.string().required("Description is required"),
//   });

//   const handleSubmit = async (values) => {
//     setIsSaving(true);

//     try {
//         const res = await postData({
//           endpoint: "FeedbackSupport/addFeedbackSupport",
//           token: cookies.b_t,
//           data: { ...values, business_id :  cookies.bid},
//         });
//         if (res) {
//           toast.success("Support request Sent Sucessfully", {
//             style: confimationStyles,
//             duration: 1000,
//           });
//           onClose()
//         }
//       } catch (error) {
//         toast.error("An Error Occured Please try again later", {
//           style: errorStyles,
//           duration: 1000,
//         });
//       } finally {
//         setIsSaving(false);
//       }
//   };
//   return (
//     <Formik
//       initialValues={initialData}
//       validationSchema={validationSchema}
//       enableReinitialize
//       validateOnBlur
//       validateOnChange
//     >
//       {({
//         dirty,
//         isValid,
//         values,
//         errors,
//         touched,
//         setFieldTouched,
//         setFieldValue,
//         handleBlur,
//       }) => (
//         <>
//           <div className="form-group">
//             <label htmlFor="" >Full Name</label>
//             <Field
//               type="text"
//               id="user_name"
//               name="user_name"
//               placeholder="Enter Full Name"
//               className={`${
//                 touched.user_name && errors.user_name ? "error-input" : ""
//               }`}
//             />
//             {touched.user_name && errors.user_name && (
//               <Error error={errors.user_name} />
//             )}
//           </div>
//           <div className="form-group mt-3">
//             <label htmlFor="" >Email Id</label>
//             <Field
//               type="text"
//               id="business_email"
//               name="business_email"
//               placeholder="Enter Email"
//               className={`${
//                 touched.business_email && errors.business_email ? "error-input" : ""
//               }`}
//             />
//             {touched.business_email && errors.business_email && <Error error={errors.business_email} />}
//           </div>
//           <div className="form-group mt-3">

//             <label htmlFor="">Title</label>
//             <Field
//               type="text"
//               id="title"
//               name="title"
//               placeholder="Enter Title"
//               className={`${
//                 touched.title && errors.title ? "error-input" : ""
//               }`}
//             />
//             {touched.title && errors.title && <Error error={errors.title} />}
//           </div>
//           <div className="form-group mt-3">

//             <label htmlFor="">Description</label>

//             <Field
//               type="text"
//               as='textarea'
//               rows={2}
//               id="description"
//               name="description"
//               placeholder="Enter Description"
//               className={`${
//                 touched.description && errors.description ? "error-input" : ""
//               }`}
//             />
//             {touched.description && errors.description && (
//               <Error error={errors.description} />
//             )}
//           </div>

//           <div className="d-flex justify-content-end mt-3">
//             <button className="btn btn-primary w-20" disabled={!isValid || !dirty} onClick={() =>  handleSubmit(values)} type="submit">
//                 Send
//             </button>
//           </div>
//         </>
//       )}
//     </Formik>
//   );
// };

// export default SupportRequestForm;

import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { useCookies } from "react-cookie";
import InputLayout from "../../../atoms/InputLayout";
import Error from "../../../atoms/Error";
import { postData } from "../../../../services";
import toast from "react-hot-toast";
import { confimationStyles, errorStyles } from "../../../../assets/styles/toast";
import { decodeCookieValue } from "../../../../helpers/cookieHelper";

const SupportRequestForm = ({ onClose }) => {
  const [cookies] = useCookies(["bid", "b_t",'bun','be']);
  const [isSaving, setIsSaving] = useState(false);

  const initialData = {
    user_name: decodeCookieValue(cookies.bun) || '',
    business_email: decodeCookieValue(cookies.be) || "",
    title: "",
    description: "",
  };

  const validationSchema = yup.object().shape({
    user_name: yup.string().required("User Name is required"),
    business_email: yup
      .string()
      .required("Email is required")
      .email("Invalid email"),
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
  });

  const handleSubmit = async (values, { setSubmitting, setTouched }) => {
    setSubmitting(true);
    setTouched({
      user_name: true,
      business_email: true,
      title: true,
      description: true,
    });

    try {
      const res = await postData({
        endpoint: "FeedbackSupport/addFeedbackSupport",
        token: cookies.b_t,
        data: { ...values, business_id: cookies.bid },
      });
      if (res) {
        toast.success("Support request Sent Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        onClose();
      }
    } catch (error) {
      toast.error("An Error Occurred. Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setSubmitting(false);
      setIsSaving(false);
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ errors, touched, handleSubmit, isSubmitting }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="user_name">Full Name</label>
            <Field
              type="text"
              id="user_name"
              name="user_name"
              placeholder="Enter Full Name"
              className={`${
                touched.user_name && errors.user_name ? "error-input" : ""
              }`}
            />
            {touched.user_name && errors.user_name && (
              <Error error={errors.user_name} />
            )}
          </div>

          <div className="form-group mt-3">
            <label htmlFor="business_email">Email Id</label>
            <Field
              type="text"
              id="business_email"
              name="business_email"
              placeholder="Enter Email"
              className={`${
                touched.business_email && errors.business_email
                  ? "error-input"
                  : ""
              }`}
            />
            {touched.business_email && errors.business_email && (
              <Error error={errors.business_email} />
            )}
          </div>

          <div className="form-group mt-3">
            <label htmlFor="title">Title</label>
            <Field
              type="text"
              id="title"
              name="title"
              placeholder="Enter Title"
              className={`${
                touched.title && errors.title ? "error-input" : ""
              }`}
            />
            {touched.title && errors.title && <Error error={errors.title} />}
          </div>

          <div className="form-group mt-3">
            <label htmlFor="description">Description</label>
            <Field
              as="textarea"
              rows={2}
              id="description"
              name="description"
              placeholder="Enter Description"
              className={`${
                touched.description && errors.description ? "error-input" : ""
              }`}
            />
            {touched.description && errors.description && (
              <Error error={errors.description} />
            )}
          </div>

          <div className="d-flex justify-content-end mt-3">
            <button
              className="btn btn-primary w-20"
              type="submit"
              disabled={isSubmitting || isSaving}
            >
              {isSubmitting || isSaving ? "Submitting..." : "Send"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SupportRequestForm;


import React, { useState, useCallback, useEffect } from "react";
import PageHeader from "../../../atoms/PageHeader";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import { setLoading } from "../../../../store/slices/loaderSlice";
import { getData, postData } from "../../../../services/index";
import View from "../../../icons/View";
import Edit from "../../../icons/Edit";
import Delete from "../../../icons/Delete";

import { useDelete } from "../../../../hooks/useDelete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import CustomButton from "../../../atoms/CustomButton";
import PageCard from "../../../atoms/PageCard";
import Search from "../../../molecules/search";
import Upload from "../../../icons/Upload";
import Pagination from "../../../molecules/Pagination";
import NoContentCard from "../../../atoms/NoContentCard";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import NoContentIcon from "../../../icons/NocontentIcon";
import Modal from "../../../organisms/modals/Modal";
import ViewDetails from "./View";
import Download from "../../../icons/Download";
import { downloadReport } from "../../../../services/downloadService";
import {
  getEmployeeVenueList,
  getVenueList,
} from "../../../../services/basicGets";
import { statusData } from "../../../../helpers/staticData";
import FilterComponent from "../../../organisms/filters/FilterComponent";
import CustomMenu from "../../../atoms/CustomMenu";
import Import from "./Import";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { decodeCookieValue } from "../../../../helpers/cookieHelper";
import ResendEmail from "./ResendEmail";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import Send from "../../../icons/Send";
import { MODE_EMPLOYEE } from "../../../../helpers/constants";

const Employees = ({ mode }) => {
  const [cookies] = useCookies(["b_t", "bid", "br", "buid",'be']);
  const role = decodeCookieValue(cookies.br);
  const businessEmail = decodeCookieValue(cookies.be)

  const navigate = useNavigate();
  const screenWidth = useScreenWidth();

  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [venueData, setVenueData] = useState([]);
  const [isImportActive, setIsImportActive] = useState(false);
  const [filters, setFilters] = useState({});
  const [isResendEmailModalActive, setIsResendEmailModalActive] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
    isViewModalOpen: false,
    data: null,
  });

  const sendLink = async ({ name, id, email }) => {
    const res = await postData({
      endpoint: "Employe/sendCreatePasswordLink",
      token: cookies.t,
      data: [id],
    });

    if (res) {
      toast(`Password creation link Sent successfully to ${email}`, {
        style: confimationStyles,
        duration: 1000,
      });
    }
  };

  const menu = [
    {
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        setAnchorEl(null);
        handleView(selectedRow);
      },
    },
  ];
  if (role !== "Viewer") {
    menu.push(
      {
        icon: <Edit height="20" width="20" />,
        label: "Edit",
        onClick: () => {
          navigate(`edit/${selectedRow?.id}`);
        },
      }
    );
  
    if (businessEmail !== selectedRow?.email) {
      menu.push({
        icon: <Delete />,
        label: "Delete",
        onClick: () => {
          setAnchorEl(null);
          setModalState((prevState) => ({
            ...prevState,
            isDeleteModalOpen: true,
            modalId: selectedRow.id,
            name: selectedRow.name,
          }));
        },
      });
    }
  }
  

  // useEffect(() => {
    if ((!selectedRow?.status || selectedRow?.status === "Pending" ) && businessEmail !== selectedRow?.email ) {
      menu.push({
        icon: <Send />,
        label: "Send Password Link",
        onClick: () => {
          sendLink({
            email: selectedRow.email,
            id: selectedRow.id,
            name: selectedRow.name,
          });
          setAnchorEl(null);
        },
      });
    }
  // }, [selectedRow]);

  console.log("check", mode === MODE_EMPLOYEE);

  const getEmployeesData = useCallback(async () => {
    const params = {
      search_query: searchedValue,
      page: currentPage,
      page_limit: itemsPerPage,
      business_id: cookies.bid,
      venues:
        filters?.Venues?.length > 0
          ? JSON.stringify(filters?.Venues) // JSON.stringify only if length > 0
          : mode === MODE_EMPLOYEE
          ? (() => {
              const adminVenueIds = venueData
                .filter((venue) => venue.venue_admin)
                .map((venue) => venue.value);

              return adminVenueIds.length > 0
                ? JSON.stringify(adminVenueIds)
                : null;
            })()
          : null,
      status:
        filters?.Status?.length > 0 ? JSON.stringify(filters?.Status) : null,
    };

    try {
      setLoading(true);
      const res = await getData({
        endpoint: "Employe/getAllEmployee",
        params: params,
        token: cookies.b_t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    } finally {
      setLoading(false); // Always ensure loading is stopped after data fetch
    }
  }, [
    searchedValue,
    cookies.b_t,
    currentPage,
    itemsPerPage,
    filters,
    mode,
    venueData,
  ]);

  useEffect(() => {
    if (venueData.length > 0 && mode) {
      getEmployeesData();
    }
  }, [getEmployeesData]);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "employee_id",
      headerName: "Employee ID",
      ...(screenWidth < 1230 ? { width: 150 } : { flex: 1 }),
      sortable: true,
    },
    {
      field: "name",
      headerName: "Employee Name",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      sortable: true,
    },
    {
      field: "contact_details",
      headerName: "Contact Details",
      ...(screenWidth < 1230 ? { width: 150 } : { flex: 1.5 }),
      sortable: true,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-column w-100">
            <span>
              {params.row.phone_number && params.row.country_code}{" "}
              {params.row.phone_number}
            </span>
            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }} title={params.row.email}>
              {params.row.email}
            </span>
          </div>
        );
      },
    },
    {
      field: "venue_name",
      headerName: "Venue",
      ...(screenWidth < 1230 ? { width: 150 } : { flex: 1 }),
      sortable: true,
    },
    {
      field: "designation",
      headerName: "Designation",
      ...(screenWidth < 1230 ? { width: 150 } : { flex: 1 }),
      sortable: true,
    },

    {
      field: "department",
      headerName: "Department",
      ...(screenWidth < 1230 ? { width: 150 } : { flex: 1 }),
      sortable: true,
    },
    {
      field: "is_active",
      headerName: "Status",
      ...(screenWidth < 1230 ? { width: 100 } : { flex: 1 }),
      renderCell: (params) => {
        const statusClasses = {
          Active: "text-success",
          Inactive: "text-error",
          Pending: "text-warning",
        };

        // Access the status from params.row and assign the appropriate class
        const status = params?.row?.status;
        const statusClass = statusClasses[status] || ""; // Default to empty string if status not found

        return (
          <>
            {/* Corrected syntax for className */}
            <span className={`${statusClass} fw-bold`}>{status}</span>
          </>
        );
      },
    },
    {
      headerName: "Actions",
      ...(screenWidth < 1230 ? { width: 100 } : { flex: 0.5 }),
      renderCell: (params) => {
        return (
          <>
            {mode === MODE_EMPLOYEE && params.row.id === cookies.buid ? (
              <></>
            ) : (
              <div className="table-icons-container">
                <div onClick={(event) => handleStatusMenu(event, params.row)}>
                  <HorizontalMenu />
                </div>

                <CustomMenu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedRow === params.row}
                  onClose={handleClose}
                  items={menu}
                />
              </div>
            )}
          </>
        );
      },
    },
  ];

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "BusinessReport/getReportOfEmployees",
      token: cookies.b_t,
      fileName: "Employees",
      params: {
        business_id: cookies.bid,
        venues:
          filters?.Venues?.length > 0 ? JSON.stringify(filters?.Venues) : null,
        status: filters?.Status?.length === 1 ? filters?.Status[0] : null,
      },
    });

    if (res) {
      toast.success("Employees Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {!!searchedValue || role === "Viewer" ? (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        ) : (
          <NoContentCard
            title="No Employee Added Yet!"
            subtitle="Click on Add New Employee button to add a employee"
            tag="Add New Employee"
            handleClick={() => navigate("add")}
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        )}
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  // deletion --------------------------------------------
  const deleteEmployee = useDelete(mode);

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteEmployee("Employe/deleteEmployee", "Employee", {
        id: modalState.modalId,
      });
      getEmployeesData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      toast.error("An Error Occured While Deleting", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  // view

  const handleView = async (data) => {
    const details = await getData({
      endpoint: "Employe/getEmployeeDetailsById",
      token: cookies.b_t,
      params: { id: data.id },
    });

    if (details) {
      setModalState((prevState) => ({
        ...prevState,
        isViewModalOpen: true,
        modalId: data.id,
        data: details,
      }));
    }
  };

  useEffect(() => {
    (async () => {
      if (mode === MODE_EMPLOYEE) {
        const res = await getEmployeeVenueList({
          emp_id: cookies.buid,
        });
        setVenueData(res);
      } else {
        const res = await getVenueList({
          business_id: cookies.bid,
          token: cookies.b_t,
        });
        setVenueData(res);
      }
    })();
  }, []);

  const filtersData = {
    Venues:
      mode === MODE_EMPLOYEE
        ? venueData?.filter((venue) => venue.venue_admin)
        : venueData,
    Status: statusData,
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <PageHeader title="Employees" />

        <CustomButton
          text="Send Resend Password Link"
          type="btn-primary"
          handleClick={() => setIsResendEmailModalActive(true)}
          iconRequired={false}
        />
      </div>

      <PageCard>
        <div className="mb-3 space-between">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
            placeholder="Search By Name,Email & Phone"
          />

          <div className="d-flex gap-3">
            <div className="d-flex gap-3 tab-employee-responsive-none">
              {role !== "Viewer" && (
                <>
                  <CustomButton
                    text="Import"
                    handleClick={() => setIsImportActive(true)}
                    iconRequired
                    icon={<Download />}
                  />
                  <CustomButton
                    text="Export"
                    handleClick={() => handleDownload()}
                    iconRequired
                    icon={<Upload />}
                  />
                </>
              )}
            </div>
            <FilterComponent
              filterData={filtersData}
              onFilterChange={(filters) => {
                setFilters(filters);
              }}
            />

            {role !== "Viewer" && (
              <CustomButton
                text="Add New Employee"
                type="btn-primary"
                handleClick={() => navigate("add")}
                classes={"tab-employee-responsive-none"}
              />
            )}
          </div>
        </div>

        {role !== "Viewer" && (
          <div className="d-flex justify-content-between tab-employee-responsive-block">
            <CustomButton
              text="Import"
              handleClick={() => setIsImportActive(true)}
              iconRequired
              icon={<Download />}
            />
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              iconRequired
              icon={<Upload />}
            />
          </div>
        )}
        <CustomDatagrid
          getRowId={(row) => row.id}
          rows={tableData?.data || []}
          columns={columns}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
          height={() => 70}
        />
        {tableData?.total_record > 25 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={tableData?.total_record}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}

        {modalState.isViewModalOpen && (
          <Modal
            name={`${modalState.name} Details`}
            title={`${modalState.name} Details`}
            id={modalState.modalId}
            isActive={modalState.isViewModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isViewModalOpen: false,
              }))
            }
            width="640px"
          >
            <ViewDetails data={modalState.data} />
          </Modal>
        )}

        {modalState.isDeleteModalOpen && (
          <DeleteModal
            name={modalState.name}
            id={modalState.modalId}
            isActive={modalState.isDeleteModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isDeleteModalOpen: false,
              }))
            }
            title="Employee"
            onClick={handleDeletion}
          />
        )}
      </PageCard>

      {isImportActive && (
        <Import
          isActive={isImportActive}
          onClose={() => {
            setIsImportActive(false);
            getEmployeesData();
          }}
        />
      )}

      {isResendEmailModalActive && (
        <ResendEmail
          isActive={isResendEmailModalActive}
          setIsActive={setIsResendEmailModalActive}
        />
      )}
    </>
  );
};

export default Employees;
